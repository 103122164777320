// app/javascript/controllers/offer_controller.js

// noinspection ES6CheckImport
import { Controller } from "stimulus"

function toFloat(text)
{
    let value = parseFloat(text);
    if (value == null || isNaN(value)) {
        return 0.0;
    }
    return value;
}

function toInt(text)
{
    let value = parseInt(text);
    if (value == null || isNaN(value)) {
        return 0;
    }
    return value;
}

// noinspection JSUnusedGlobalSymbols
export default class extends Controller
{
    // noinspection JSUnusedGlobalSymbols
    connect()
    {
        this.showLeasingTypeRelated($('#leasing_offer_leasing_type').val(), true);
        this.showTargetClassRelated($('#leasing_offer_target_class').val());
        this.showUnusedRelated($('#leasing_offer_target_unused').is(':checked'), true);
        this.requestExtraServices(true);
        this.calculateVehiclePrice();
        this.calculateServiceCosts();
    }

    requestExtraServices(initial)
    {
        let controller = this;
        let $extraServicesTotal = $('#leasing_offer_extra_services_total');

        $.ajax({
            type: 'GET',
            dataType: "json",
            url: '/admin/extra_services/list',
            data: null,
            success: function(response) {
                // Update values
                let services = response["services"];
                if (services != null) {
                    let total = controller.servicesTotal(services)
                    for (let i = 0; i < services.length; ++i) {
                        let service = services[i];
                        let $serviceId = $('#extra_services_id_' + service.id);
                        $serviceId.change(function() {
                            total = controller.servicesTotal(services);
                            $extraServicesTotal.val(total.toFixed(2));
                            controller.calculateMonthlyFee();
                        });
                    }
                    $extraServicesTotal.val(total.toFixed(2));
                    if (initial) {
                        controller.initListeners();
                    } else {
                        controller.calculateMonthlyFee();
                    }
                } else {
                    $extraServicesTotal.val('');
                }
            },
            error: function () {
                $extraServicesTotal.val('');
            }
        });
    }

    servicesTotal(services)
    {
        let services_total = 0;
        for (let i = 0; i < services.length; ++i) {
            let service = services[i];
            let leasingMonths = toInt($('#leasing_offer_leasing_months').val());
            let $serviceId = $('#extra_services_id_' + service.id);
            if ($serviceId.is(':checked') && leasingMonths > 0) {
                // noinspection JSUnresolvedVariable
                let price = toFloat(service.price);
                if (service.unit === 'kilometer') {
                    let kilometers = toInt($('#leasing_offer_leasing_mileage').val()) * 1000;
                    if (kilometers === 0) {
                        kilometers = toInt($('#leasing_offer_target_contract_mileage').val());
                    }
                    price = (price * kilometers) / leasingMonths;
                }
                services_total += price;
            }
        }
        return services_total;
    }

    initListeners()
    {
        let controller = this;
        let $targetMake = $('#leasing_offer_target_make');
        let $targetModel = $('#leasing_offer_target_model');
        let $targetVariant = $('#leasing_offer_target_variant');
        let $companyName = $('#leasing_offer_company_name');
        let $companyBusinessId = $('#leasing_offer_company_business_id');

        // Leasing type
        $('#leasing_offer_leasing_type').on('change', function() {
            let type = $(this).val();
            //console.log("Leasing type selected: " + type);
            controller.showLeasingTypeRelated(type, false)
        });

        // Target class
        $('#leasing_offer_target_class').on('change', function() {
            let target = $(this).val();
            //console.log("Target class selected: " + target);
            controller.showTargetClassRelated(target)
        });

        // New item
        $('#leasing_offer_target_unused').on('change', function() {
            let unused = $(this).is(':checked');
            //console.log("Unused: " + unused);
            controller.showUnusedRelated(unused, false)
        });

        // Make
        $targetMake.on('keyup', function() {
            // Clear model and variant if make is cleared
            if ($targetMake.val().length > 0) {
                controller.requestMakeList();
            } else {
                $targetModel.val('');
                $targetVariant.val('');
                $("#target_make_list").empty();
                controller.clearGreyhenRelatedData();
            }
        });

        // Model
        $targetModel.on('keyup', function() {
            if ($targetMake.val().length > 0 && $targetModel.val().length > 0) {
                controller.requestModelList($targetMake.val());
            } else {
                $targetVariant.val('');
                $("#target_model_list").empty();
                controller.clearGreyhenRelatedData();
            }
        });
        $targetModel.on('click', function() {
            if ($targetMake.val().length > 0 && $targetModel.val().length === 0) {
                controller.requestModelList($targetMake.val());
            }
        });

        // Variant
        $targetVariant.on('keyup', function() {
            if ($targetMake.val().length > 0 && $targetModel.val().length > 0) {
                controller.requestVariantList($targetMake.val(), $targetModel.val());
            }
        });
        $targetVariant.on('click', function() {
            if ($targetMake.val().length > 0 && $targetModel.val().length > 0 &&
                $targetVariant.val().length === 0) {
                controller.requestVariantList($targetMake.val(), $targetModel.val());
            }
        });

        // Company name
        $companyName.on('keyup', function() {
            if ($companyName.val().length > 0) {
                controller.requestCompanyList();
            }
        });

        // Company business id
        $companyBusinessId.on('keyup', function() {
            if ($companyBusinessId.val().length > 0) {
                controller.requestBusinessIdList();
            }
        });

        // Clear selection lists
        $('html').click(function() {
            // Hide lists when clicking somewhere
            $("#target_make_list").empty();
            $("#target_model_list").empty();
            $("#target_variant_list").empty();
            $("#company_name_list").empty();
            $("#company_business_id_list").empty();
        });

        let $greyhenId = $("#leasing_offer_greyhen_data");
        let $leasingMonths = $("#leasing_offer_leasing_months");
        let $leasingMileage = $("#leasing_offer_leasing_mileage");
        let $targetServicesPrice = $('#leasing_offer_target_services_price');

        // Year model
        let $targetYearModel = $('#leasing_offer_target_year_model');
        $targetYearModel.on('keyup', function() {
            controller.requestRemaining($greyhenId.val(), $leasingMonths.val(), $leasingMileage.val());
        });

        // Months
        $leasingMonths.on('change', function() {
            let months = $(this).val();
            controller.requestRemaining($greyhenId.val(), months, $leasingMileage.val());
        });

        // Mileage
        $leasingMileage.on('change', function() {
            if ($('#leasing_offer_manual_mode').is(':checked')) { return }

            let mileage = toInt($(this).val());
            let serviceFee = mileage * 1000 * 0.025
            $targetServicesPrice.val(serviceFee.toFixed(2));

            let realMileage = mileage * 1000;
            if (realMileage > 0) {
                $("#leasing_offer_target_contract_mileage").val(realMileage);
            } else {
                $("#leasing_offer_target_contract_mileage").val('');
            }
            controller.requestRemaining($greyhenId.val(), $leasingMonths.val(), mileage);
        });

        // Extra services
        let $extraServicesTotal = $("#leasing_offer_extra_services_total");
        $extraServicesTotal.on('keyup', function() {
            controller.requestRemaining(
                $greyhenId.val(), $leasingMonths.val(), $leasingMileage.val());
        });

        // Image
        let $targetImageUrl = $('#leasing_offer_target_image_url');
        $targetImageUrl.on('keyup', function() {
            //console.log("URL: " + $targetImageUrl.val());
            $('#target_image_url').attr('src', $targetImageUrl.val());
        });

        // Original price
        let $targetOriginalPrice = $('#leasing_offer_target_original_price');
        $targetOriginalPrice.on('keyup', function() {
            controller.calculateMonthlyFee();
        });

        // Cash price
        let $targetCashPrice = $('#leasing_offer_target_cash_price');
        let $targetAccessoriesTotal = $('#leasing_offer_target_accessories_total');
        let $targetDiscount = $('#leasing_offer_target_discount');

        $targetCashPrice.on('keyup', function() {
            controller.calculateCashPriceTotal(controller);
            controller.calculateVehiclePrice();
        });
        $targetCashPrice.on('change', function() {
            controller.calculateVehiclePrice();
        });

        // Accessories
        $targetAccessoriesTotal.on('keyup', function() {
            controller.calculateCashPriceTotal(controller);
            controller.calculateVehiclePrice();
        });
        $targetAccessoriesTotal.on('change', function() {
            controller.calculateVehiclePrice();
        });

        // Discount
        $targetDiscount.on('keyup', function() {
            controller.calculateCashPriceTotal(controller);
            controller.calculateVehiclePrice();
        });
        $targetDiscount.on('change', function() {
            controller.calculateVehiclePrice();
        });

        // Contribution margin
        $('#leasing_offer_contribution_margin').on('keyup', function() {
            controller.calculateCashPriceTotal(controller);
        });

        // Services price
        $targetServicesPrice.on('keyup', function() {
            controller.calculateCashPriceTotal(controller);
            controller.calculateServiceCosts();
        });
        $targetServicesPrice.on('change', function() {
            controller.calculateServiceCosts();
        });

        // Tyres storage
        let $targetTyresStorage = $('#leasing_offer_target_tyres_storage')
        $targetTyresStorage.on('keyup', function() {
            controller.calculateMonthlyFee();
            controller.calculateServiceCosts();
        });
        $targetTyresStorage.on('change', function() {
            controller.calculateServiceCosts();
        });

        // Tyres purchase
        let $targetTyresPurchase = $('#leasing_offer_target_tyres_purchase')
        $targetTyresPurchase.on('keyup', function() {
            controller.calculateCashPriceTotal(controller);
            controller.calculateServiceCosts();
        });
        $targetTyresPurchase.on('change', function() {
            controller.calculateServiceCosts();
        });

        // Service budget
        $("#leasing_offer_target_service_budget").on('keyup', function() {
            controller.calculateMonthlyFee();
        });

        // Furniture price
        $('#leasing_offer_target_price_furniture').on('keyup', function() {
            controller.calculateCashPriceTotal(controller);
        });

        // HYA discount
        $('#leasing_offer_target_preliminary_result').on('keyup', function() {
            controller.calculateCashPriceTotal(controller);
        });

        // HYA discount
        $('#leasing_offer_target_discount_hya').on('keyup', function() {
            controller.calculateCashPriceTotal(controller);
        });

        // Remain value
        $("#leasing_offer_target_remains_value").on('keyup', function() {
            controller.calculateMonthlyFee();
        });

        // Contract mileage
        $("#leasing_offer_target_contract_mileage").on('keyup', function() {
            controller.requestExtraServices(false);
        });

        // Payment handling
        $("#leasing_offer_payment_handling").on('keyup', function() {
            controller.calculateMonthlyFee();
        });

        // Payment expenses
        $("#leasing_offer_payment_expenses").on('keyup', function() {
            controller.calculateMonthlyFee();
        });
    }

    showLeasingTypeRelated(type, initial)
    {
        // Changing company field names to customer field names
        // Example 'Yritys & yhteyshenkilö' to 'Asiakkaan & haltijantiedot'
        if (type === 'private_leasing') {
            $('#target_company_heading').text('Asiakkaan & haltijantiedot');
            $('#target_company_name').text('Nimi');
            $('#target_company_business_id').text('Hetu');
            $('#target_company_contact_name').text('Haltijantiedot');
        } else {
            $('#target_company_heading').text('Yritys & yhteyshenkilö');
            $('#target_company_name').text('Yritys');
            $('#target_company_business_id').text('Y-tunnus');
            $('#target_company_contact_name').text('Yhteyshenkilön nimi');
        }

        if (type === 'funding_leasing' || type === 'machine_leasing') {
            $('.funding_leasing_show').removeClass('hide');
            $('.service_leasing_show').addClass('hide');
            $('.service_hya_leasing_show').addClass('hide');
            $('.flex_leasing_show').addClass('hide');
            $('.hya_leasing_show').addClass('hide');
        } else if (type === 'service_leasing' || type === 'private_leasing') {
            $('.funding_leasing_show').addClass('hide');
            $('.service_leasing_show').removeClass('hide');
            $('.service_hya_leasing_show').removeClass('hide');
            $('.flex_leasing_show').addClass('hide');
            $('.hya_leasing_show').addClass('hide');
        } else if (type === 'flex_leasing') {
            $('.funding_leasing_show').addClass('hide');
            $('.service_leasing_show').removeClass('hide');
            $('.service_hya_leasing_show').addClass('hide');
            $('.flex_leasing_show').removeClass('hide');
            $('.hya_leasing_show').addClass('hide');
        } else if (type === 'hya_leasing') {
            $('.funding_leasing_show').addClass('hide');
            $('.service_leasing_show').addClass('hide');
            $('.service_hya_leasing_show').removeClass('hide');
            $('.flex_leasing_show').removeClass('hide');
            $('.hya_leasing_show').removeClass('hide');
        }
        this.calculateCashPriceTotal(this);

        if (!initial) {
            let $commonService = $("#leasing_offer_common_service");
            let targetServiceBudget = $("#leasing_offer_target_service_budget");
            if (type === 'funding_leasing' || type === 'hya_leasing') {
                $commonService.val("Auton hankinta ja rahoitus\r\n");
                targetServiceBudget.val('');
            } else if (type === 'service_leasing' || type === 'private_leasing') {
                $commonService.val(
                    "Auton hankinta ja rahoitus\r\n" +
                    "Sovittu km-määrä sopimuskaudelle\r\n" +
                    "Määräaikaishuollot\r\n" +
                    "Katsastukset sekä rekisteröinti\r\n" +
                    "Kesä- ja talvirenkaat vanteineen\r\n" +
                    "Rengas huolto, kausisäilytys ja -vaihto\r\n" +
                    "Ajoneuvon uudelleensijoitus vuokrakauden päätteeksi\r\n");
                targetServiceBudget.val('');
            } else if (type === 'flex_leasing') {
                $commonService.val(
                    "Katsastus ja rekistöröinti\r\n" +
                    "Auton hankinta ja rahoitus\r\n" +
                    "Sovittu km-määrä sopimuskaudelle\r\n" +
                    "Määräaikaishuollot budjetin puitteissa\r\n" +
                    "Kesä- ja talvirenkaat vanteineen budjetin puitteissa\r\n" +
                    "Rengas huolto, kausisäilytys ja -vaihto budjetin puitteissa\r\n" +
                    "Ajoneuvon uudelleensijoitus vuokrakauden päätteeksi asiakkaan lukuun\r\n");
            } else if (type === 'machine_leasing') {
                $commonService.val("Kone/Laite hankinta ja rahoitus\r\n");
                targetServiceBudget.val('');
            }
        }
    }

    showTargetClassRelated(target)
    {
        let $targetUnused = $('#leasing_offer_target_unused');

        if (target === 'car') {
            $targetUnused.removeClass('hide');
            $('#label_target_unused').removeClass('hide');
        } else {
            $targetUnused.prop("checked", false);
            this.showUnusedRelated(false, false);
            $targetUnused.addClass('hide');
            $('#label_target_unused').addClass('hide');
        }
    }

    showUnusedRelated(unused, initial)
    {
        if (unused) {
            $('.unused_show').removeClass('hide');
            // List price
            // Plate
            $('.unused_hide').addClass('hide');
            // Year model
            if (!initial) {
                $('#leasing_offer_target_year_model').val(new Date().getFullYear()); // Use current year
            }
            // Mileage
            if (!initial) {
                $('#leasing_offer_target_mileage').val(0);
            }
            // Original price
            // Original date
        } else {
            $('.unused_show').addClass('hide');
            // List price
            // Plate
            $('.unused_hide').removeClass('hide');
            // Year model
            // Mileage
            // Original price
            // Original date
        }
    }

    requestMakeList()
    {
        let $targetMake = $('#leasing_offer_target_make');
        let $targetMakeList = $("#target_make_list");

        $.ajax({
            type: 'GET',
            dataType: "json",
            url: '/admin/greyhens/makes_list',
            data: { make: $targetMake.val() },
            success: function(response) {
                $targetMakeList.empty();
                $targetMakeList.children().unbind();
                // Update list items
                let makes = response["makes"];
                if (makes.length > 0 && makes[0] !== $targetMake.val()) {
                    for (let i = 0; i < makes.length; ++i) {
                        $targetMakeList.append('<li>' + makes[i] + '</li>');
                    }
                    $targetMakeList.children().on('click', function() {
                        $targetMake.val(this.textContent);
                        $targetMakeList.empty();
                    });
                }
            }
        });
    }

    requestModelList(make)
    {
        let $targetModel = $('#leasing_offer_target_model');
        let $targetModelList = $("#target_model_list");

        $.ajax({
            type: 'GET',
            dataType: "json",
            url: '/admin/greyhens/models_list',
            data: { make: make, model: $targetModel.val() },
            success: function(response) {
                $targetModelList.empty();
                $targetModelList.children().unbind();
                // Update list items
                let models = response["models"];
                if (models.length > 0 && models[0] !== $targetModel.val()) {
                    for (let i = 0; i < models.length; ++i) {
                        $targetModelList.append('<li>' + models[i] + '</li>');
                    }
                    $targetModelList.children().on('click', function() {
                        $targetModel.val(this.textContent);
                        $targetModelList.empty();
                    });
                }
            }
        });
    }

    requestVariantList(make, model)
    {
        let controller = this;
        let $targetVariant = $('#leasing_offer_target_variant');
        let $targetVariantList = $("#target_variant_list");

        $.ajax({
            type: 'GET',
            dataType: "json",
            url: '/admin/greyhens/variants_list',
            data: { make: make, model: model, variant: $targetVariant.val() },
            success: function(response) {
                $targetVariantList.empty();
                $targetVariantList.children().unbind();
                // Update list items
                let variants = response["variants"];
                if (variants.length > 0 && variants[0] !== $targetVariant.val()) {
                    for (let i = 0; i < variants.length; ++i) {
                        $targetVariantList.append('<li>' + variants[i] + '</li>');
                    }
                    $targetVariantList.children().on('click', function() {
                        $targetVariant.val(this.textContent);
                        controller.requestGreyhenData(make, model, this.textContent);
                        $targetVariantList.empty();
                    });
                }
            }
        });
    }

    requestGreyhenData(make, model, variant)
    {
        let controller = this;

        $.ajax({
            type: 'GET',
            dataType: "json",
            url: '/admin/greyhens/car_data',
            data: { make: make, model: model, variant: variant },
            success: function(response) {
                // Update values
                let greyhen_data = response["greyhen_data"];
                if (greyhen_data != null) {
                    //console.log("Greyhen ID: " + greyhen_data.id);
                    $("#leasing_offer_greyhen_data").val(greyhen_data.id);
                    // noinspection JSUnresolvedVariable
                    let listPrice = greyhen_data.list_price;
                    $('#leasing_offer_target_list_price').val(listPrice.toFixed(2));
                    $('#leasing_offer_target_original_price').val(listPrice.toFixed(2));
                    $("#leasing_offer_target_cash_price").val(listPrice.toFixed(2));
                    // noinspection JSUnresolvedVariable
                    $("#leasing_offer_target_c02_emission").val(greyhen_data.c02_emission);
                    // noinspection JSUnresolvedVariable
                    $("#leasing_offer_target_automobile_tax").val(greyhen_data.automobile_tax);

                    controller.calculateCashPriceTotal(controller);
                    let leasingMonths = $("#leasing_offer_leasing_months").val();
                    let leasingMileage = $("#leasing_offer_leasing_mileage").val();
                    controller.requestRemaining(greyhen_data.id, leasingMonths, leasingMileage);
                }
            }
        });
    }

    clearGreyhenRelatedData()
    {
        $("#leasing_offer_greyhen_data").val('');
        $('#leasing_offer_target_list_price').val('');
        $("#leasing_offer_target_cash_price").val('');
        $("#leasing_offer_target_services_price").val('');
        $("#leasing_offer_target_service_budget").val('');
        $("#leasing_offer_target_cash_price_total").val('');
        $("#leasing_offer_target_c02_emission").val('');
        $("#leasing_offer_target_automobile_tax").val('');
        $("#leasing_offer_target_remains_value").val('');
        $("#leasing_offer_target_contract_mileage").val('');
        $("#leasing_offer_payment_months").val('');
        $("#leasing_offer_target_full_leasing").val('');
        $("#leasing_offer_target_usage_based_leasing").val('');
        $("#leasing_offer_payment_per_month").val('');
    }

    requestCompanyList()
    {
        let controller = this;
        let $companyName = $('#leasing_offer_company_name');
        let $companyNameList = $("#company_name_list");

        $.ajax({
            type: 'GET',
            dataType: "json",
            url: '/admin/customers/company_list',
            data: { company_name: $companyName.val() },
            success: function(response) {
                $companyNameList.empty();
                $companyNameList.children().unbind();
                // Update list items
                let customers = response["customers"];
                if (customers.length > 0 && customers[0] !== $companyName.val()) {
                    for (let i = 0; i < customers.length; ++i) {
                        $companyNameList.append('<li><span style="visibility: hidden">' +
                            i + '-' + '</span>' + customers[i].company_name + '</li>');
                    }
                    $companyNameList.children().on('click', function() {
                        $companyNameList.empty();
                        controller.updateCustomer(this.textContent, customers);
                    });
                }
            }
        });
    }

    requestBusinessIdList()
    {
        let controller = this;
        let $companyBusinessId = $('#leasing_offer_company_business_id');
        let $companyBusinessIdList = $("#company_business_id_list");

        $.ajax({
            type: 'GET',
            dataType: "json",
            url: '/admin/customers/business_id_list',
            data: { business_id: $companyBusinessId.val() },
            success: function(response) {
                $companyBusinessIdList.empty();
                $companyBusinessIdList.children().unbind();
                // Update list items
                let customers = response["customers"];
                if (customers.length > 0 && customers[0] !== $companyBusinessId.val()) {
                    for (let i = 0; i < customers.length; ++i) {
                        $companyBusinessIdList.append('<li><span style="visibility: hidden">' +
                            i + '</span>' + customers[i].company_business_id + '</li>');
                    }
                    $companyBusinessIdList.children().on('click', function() {
                        $companyBusinessIdList.empty();
                        controller.updateCustomer(this.textContent, customers);
                    });
                }
            }
        });
    }

    updateCustomer(item, customers)
    {
        let i = toInt(item);
        //console.log("INDEX: " + i);
        $('#leasing_offer_customer_id').val(customers[i].id);
        $('#leasing_offer_company_name').val(customers[i].company_name);
        $('#leasing_offer_company_business_id').val(customers[i].company_business_id);
        $('#leasing_offer_company_address').val(customers[i].company_address);
        $('#leasing_offer_company_zip_code').val(customers[i].company_zip_code);
        $('#leasing_offer_company_locality').val(customers[i].company_locality);
        $('#leasing_offer_contact_name').val(customers[i].contact_name);
        $('#leasing_offer_contact_email').val(customers[i].contact_email);
        $('#leasing_offer_contact_phone').val(customers[i].contact_phone);
        $('#leasing_offer_footnote').val(customers[i].info);
    }

    requestRemaining(id, months, mileage)
    {
        let controller = this;
        if ($('#leasing_offer_manual_mode').is(':checked')) { return }

        let targetYearModel = $('#leasing_offer_target_year_model').val()
        let targetMileage = $('#leasing_offer_target_mileage').val();
        if (toInt(mileage) > 0) {
            $.ajax({
                type: 'GET',
                dataType: "json",
                url: '/admin/greyhens/remaining',
                data: { id: id, leasing_months: months, leasing_mileage: mileage,
                    target_year_model: targetYearModel, target_mileage: targetMileage },
                success: function (response) {
                    let remain = response["remaining"];
                    if (remain != null) {
                        $("#leasing_offer_target_remains_value").val(remain.toFixed(2));
                        controller.calculateMonthlyFee();
                    } else {
                        $("#leasing_offer_target_remains_value").val('');
                    }
                    $("#leasing_offer_payment_months").val(months);
                    controller.calculateServiceCosts();
                },
                error: function () {
                    $("#leasing_offer_target_remains_value").val('');
                    $("#leasing_offer_payment_months").val('');
                    controller.calculateMonthlyFee();
                    controller.calculateServiceCosts();
                }
            });
        } else {
            $("#leasing_offer_target_remains_value").val('');
            $("#leasing_offer_payment_months").val('');
            controller.calculateMonthlyFee();
            controller.calculateServiceCosts();
        }
    }

    calculateCashPriceTotal(controller)
    {
        if ($('#leasing_offer_manual_mode').is(':checked')) { return }

        let cashPrice = $('#leasing_offer_target_cash_price').val();
        let contributionMargin = $('#leasing_offer_contribution_margin').val();
        let accessories = $('#leasing_offer_target_accessories_total').val();
        let discount = $('#leasing_offer_target_discount').val();
        let services = $('#leasing_offer_target_services_price').val();
        let tyresStorage = $('#leasing_offer_target_tyres_storage').val();
        let tyresPurchase = $('#leasing_offer_target_tyres_purchase').val();
        let cashPriceTotal;

        let type = $('#leasing_offer_leasing_type').val();
        if (type === 'funding_leasing' || type === 'machine_leasing') {
            cashPriceTotal = toFloat(cashPrice) + toFloat(contributionMargin);
        } else if (type === 'service_leasing' || type === 'flex_leasing' || type === 'private_leasing') {
            if (type === 'flex_leasing') {
                controller.calculateBudget(services, tyresPurchase, tyresStorage);
            }
            cashPriceTotal = toFloat(cashPrice) + toFloat(accessories) - toFloat(discount) +
                toFloat(services) + toFloat(tyresPurchase);
        } else if (type === 'hya_leasing') {
            let budget = controller.calculateBudget(
                services, tyresPurchase, tyresStorage);
            let priceFurniture = $('#leasing_offer_target_price_furniture').val();
            let cashPricePreliminary = toFloat(cashPrice) +
                toFloat(accessories) + toFloat(priceFurniture) + budget;
            $('#leasing_offer_target_preliminary_result').val(cashPricePreliminary);
            let discountHya = toFloat($('#leasing_offer_target_discount_hya').val());
            cashPriceTotal = cashPricePreliminary - discountHya;
        }
        $("#leasing_offer_target_cash_price_total").val(cashPriceTotal.toFixed(2));
        controller.calculateMonthlyFee();
    }

    calculateVehiclePrice()
    {
        let type = $('#leasing_offer_leasing_type').val();
        if (type === 'service_leasing' || type === 'flex_leasing' || type === 'private_leasing') {
            let total = toFloat($('#leasing_offer_target_cash_price').val()) +
                toFloat($('#leasing_offer_target_accessories_total').val()) -
                toFloat($('#leasing_offer_target_discount').val());
            $('#label_vehicle_price').html("Ajoneuvon hinta: " +
                total.toFixed(2) + ' €');
        }
    }

    calculateServiceCosts()
    {
        let total = toFloat($('#leasing_offer_target_services_price').val()) +
            (toFloat($('#leasing_offer_target_tyres_storage').val()) *
            toInt($('#leasing_offer_payment_months').val())) +
            toFloat($('#leasing_offer_target_tyres_purchase').val());
        $('#label_service_costs').html("Huoltokustannukset: " +
            total.toFixed(2) + ' €');
    }

    calculateBudget(services, tyresPurchase, tyresStorage)
    {
        let leasingMonths = toInt($("#leasing_offer_payment_months").val());
        let budget = toFloat(services) + toFloat(tyresPurchase) +
            toFloat(tyresStorage * leasingMonths);
        $("#leasing_offer_target_service_budget").val(budget.toFixed(2));
        return budget;
    }

    calculateMonthlyFee()
    {
        if ($('#leasing_offer_manual_mode').is(':checked')) { return }

        let taxPrice;
        if ($('#leasing_offer_target_unused').is(':checked')) {
            taxPrice = toFloat($("#leasing_offer_target_cash_price").val()) - 3400;
        } else {
            taxPrice = toFloat($("#leasing_offer_target_original_price").val()) - 3400;
        }
        //console.log("TAX PRICE: " + taxPrice);
        if (taxPrice < 0) {
            taxPrice = 0;
        }
        let yearModel = toInt($("#leasing_offer_target_year_model").val());
        let mileage = toInt($("#leasing_offer_leasing_mileage").val());
        let leasingMonths = toInt($("#leasing_offer_payment_months").val());

        $("#leasing_offer_target_full_leasing").val(this.calculateBenefitOfCompanyCar(
            taxPrice, yearModel, mileage, false));
        $("#leasing_offer_target_usage_based_leasing").val(this.calculateBenefitOfCompanyCar(
            taxPrice, yearModel, mileage, true));

        let amount = toFloat($("#leasing_offer_target_cash_price_total").val()) -
            toFloat($("#leasing_offer_target_remains_value").val());
        let tyresFeePerMonth = toFloat($("#leasing_offer_target_tyres_storage").val());
        let extra = toFloat($("#leasing_offer_extra_services_total").val()) + tyresFeePerMonth;
        this.requestLeasingFee(amount, extra, leasingMonths);
    }

    requestLeasingFee(amount, extra, months)
    {
        let monthlyFee = $("#leasing_offer_payment_handling").val();
        let serviceFee = $("#leasing_offer_payment_expenses").val();

        $.ajax({
            type: 'GET',
            dataType: "json",
            url: '/admin/leasing_offers/leasing_fee',
            data: { amount: amount, months: months, monthly_fee: monthlyFee, service_fee: serviceFee },
            success: function(response) {
                // Update values
                let total = response["total"];
                //console.log("TOTAL: " + total);
                if (total != null) {
                    let monthlyFee = total + extra;
                    $("#leasing_offer_payment_per_month").val(monthlyFee.toFixed(2));
                }
            }
        });
    }

    calculateBenefitOfCompanyCar(listPrice, yearModel, mileage, usageBenefit)
    {
        //console.log("PRICE: " + listPrice + ", YEAR: " + yearModel + ", MILEAGE: " + mileage);
        let monthFee = 300;
        let percentage = 0.009;
        let perKilometer = 0.20;
        if (usageBenefit) {
            monthFee = 135;
            percentage = 0.009;
            perKilometer = 0.09;
        }

        switch (yearModel) {
            case 2015:
            case 2016:
            case 2017:
                monthFee = 120;
                percentage = 0.012;
                perKilometer = 0.08;
                if (usageBenefit) {
                    monthFee = 285;
                    percentage = 0.012;
                    perKilometer = 0.19;
                }
                break;

            case 2018:
            case 2019:
            case 2020:
                monthFee = 270;
                percentage = 0.014;
                perKilometer = 0.18;
                if (usageBenefit) {
                    monthFee = 105;
                    percentage = 0.014;
                    perKilometer = 0.07;
                }
                break;

            default: // Older than 2015
                break;
        }
        //console.log("MONTH FEE: " + monthFee + ", PERCENTAGE: " + percentage);
        let benefit = monthFee + listPrice * percentage;
        if (mileage > 0) {
            let benefit2 = mileage * 1000 * perKilometer;
            if (benefit2 < benefit) {
                benefit = benefit2;
            }
        }
        if (listPrice < 1000) {
            return '';
        }
        return benefit.toFixed(2);
    }
}
