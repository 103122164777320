// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js.

// noinspection ES6CheckImport
import { Application } from "stimulus"
// noinspection ES6CheckImport
import { definitionsFromContext } from "stimulus/webpack-helpers"
import $ from "jquery";

const application = Application.start();
// noinspection JSUnresolvedFunction
const context = require.context("controllers", true, /_controller\.js$/);
application.load(definitionsFromContext(context));
