// app/javascript/controllers/contract_controller.js

import { Controller } from "stimulus"

function toFloat(text)
{
    let value = parseFloat(text);
    if (value == null || isNaN(value)) {
        return 0.0;
    }
    return value;
}

function toInt(text)
{
    let value = parseInt(text);
    if (value == null || isNaN(value)) {
        return 0;
    }
    return value;
}

// noinspection JSUnusedGlobalSymbols
export default class extends Controller
{
    static targets = ["leasingtype", "typevalue"];
    // noinspection JSUnusedGlobalSymbols
    connect()
    {
        //this.requestExtraServices(true);
        let leasing_type = this.hasLeasingtypeTarget.value;
        if (leasing_type) {
            this.updateLabelNames(this.leasingtypeTarget.value)
        }
    }

    requestExtraServices(initial)
    {
        let controller = this;

        $.ajax({
            type: 'GET',
            dataType: "json",
            url: '/admin/contracts/list',
            data: null,
            success: function(response) {
                // Update values
                let contracts = response["contracts"];
                if (contracts != null) {
                } else {
                }
            },
            error: function () {
            }
        });
    }

    initListeners()
    {
        let controller = this;
    }

    // Changing company name label to customer name
    updateLabelNames(leasing_type)
    {
        if (leasing_type === 'private_leasing') {
            $('#target_company_heading').text('Henkilöasiakkaan tiedot');
            $('#target_company_business_id').text('Hetu');
            $('#target_company_user_name').text('Haltijan henkilötiedot');
        } else {
            $('#target_company_heading').text('Asiakasyrityksen tiedot');
            $('#target_company_business_id').text('Y-tunnus');
            $('#target_company_user_name').text('Käyttäjän henkilötiedot');
        }
    }

    update()
    {
        this.updateLabelNames(this.typevalueTarget.value);
    }

}
